import { useCallback } from 'react';
import { isNil } from '~/utilities/type-guards';
import { Plan } from '~/graphql';
import { environment } from '~/constantDefinitions';
import { useAnonymousGetGtmDataQuery, useGetGtmDataQuery } from './__gql/useGTMQuery.gql.gen';

type GTMEventNames =
  | 'w.account.created'
  | 'w.account.purchased'
  | 'w.account.trialStarted'
  | 'w.gatedFeature.promoteUpgradeClick'
  | 'w.liveRegistrationFormSubmission'
  | 'w.pageView';

type GTMEventVars = {
  conversion_value?: string;
  planSku?: Plan['sku'];
  name?: string;
  email?: string;
};

type PushToDataLayer = (eventName: GTMEventNames, otherVars: GTMEventVars) => void;

interface UseGTMOptions {
  isAnonymous: boolean;
}

const pushToGTMDataLayer = (data: Record<string, unknown>) => {
  if (window.GTMDataLayer) {
    window.GTMDataLayer.push(data);
    return;
  }

  if (environment !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(`useGTM - Event not sent: \n ${JSON.stringify(data, null, 2)}\n`);
  }
};

const useAnonymousGTM = (): PushToDataLayer => {
  const { data, error } = useAnonymousGetGtmDataQuery();

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return useCallback(
    (eventName: GTMEventNames, otherVars?: GTMEventVars) => {
      if (isNil(data)) {
        // eslint-disable-next-line no-console
        console.warn(`useGTM: ${eventName} could not be sent. GQL data not present.`);
        return;
      }

      pushToGTMDataLayer({
        accountId: data.currentAccount?.numericId,
        ...{ event: eventName, ...(otherVars ?? {}) },
      });
    },
    [data],
  );
};

const useAuthenticatedGTM = (): PushToDataLayer => {
  const { data, error } = useGetGtmDataQuery();

  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return useCallback(
    (eventName: GTMEventNames, otherVars?: GTMEventVars) => {
      if (isNil(data) || isNil(data.currentContact) || isNil(data.currentAccount)) {
        // eslint-disable-next-line no-console
        console.warn(`useGTM: ${eventName} could not be sent. GQL data not present.`);
        return;
      }

      const isFreeOrPlusPlan =
        data.currentAccount.type === 'FreebieAccount' ||
        data.currentAccount.plan?.sku === 'plus_2022_tiered';
      const sendChurnZeroData =
        (data.currentContact.isSuperadmin || data.currentContact.isOwner) &&
        !data.currentContact.isWadmin;
      const churnZeroAccountId = sendChurnZeroData ? data.currentAccount.churnZeroAccountId : null;
      const currentContactEmail = sendChurnZeroData ? data.currentContact.email : null;

      pushToGTMDataLayer({
        accountId: data.currentAccount.numericId,
        contactId: data.currentContact.numericId,
        userId: data.currentContact.numericUserId,
        churnZeroAccountId,
        churnZeroContactId: currentContactEmail,
        isFreeOrPlusPlan,
        ...{ event: eventName, ...(otherVars ?? {}) },
      });
    },
    [data],
  );
};

export const useGTM = (opts?: UseGTMOptions): PushToDataLayer => {
  const hook = opts?.isAnonymous ? useAnonymousGTM : useAuthenticatedGTM;
  return hook();
};
