import { gql, useQuery } from '@apollo/client';
import flagsmith from 'flagsmith';
import { IFlagsmithTrait } from 'flagsmith/types';
import { FlagsmithContextType, FlagsmithProvider, useFlagsmith } from 'flagsmith/react';
import { useEffect, createContext, useState, useContext, JSX } from 'react';
import {
  flagsmithEnvironmentId,
  flagsmithAnonymousEnvironmentId,
  flagCacheTTL,
} from '../../constantDefinitions';
import { FlagsmithAnonymousIdentityAndTraitsLoader } from './FlagsmithAnonymousIdentityAndTraitsLoader';

const FlagsmithAnonymousIdentityLoadingContext = createContext(true);

export const useFlagsmithAnonymousIdentityLoading = (): boolean => {
  return useContext(FlagsmithAnonymousIdentityLoadingContext);
};

// TODO: Tech debt - Migrate code to use codegen hooks with Typescript safety
// eslint-disable-next-line no-restricted-syntax
const identityAndTraitsQuery = gql`
  query IdentityAndTraitsQuery {
    currentActor {
      id

      flagsmithId
      flagsmithIdentityData
    }
  }
`;
type CurrentActor = {
  flagsmithId: string;
  flagsmithIdentityData: Record<string, IFlagsmithTrait>;
};

type QueryData = {
  currentActor: CurrentActor;
};

type QueryResult = {
  loading: boolean;
  data: QueryData | undefined;
};

const FlagsmithIdentityLoadingContext = createContext(true);

export const useFlagsmithIdentityLoading = (): boolean => {
  return useContext(FlagsmithIdentityLoadingContext);
};

const FlagsmithIdentityAndTraitsLoader = ({
  setLoading,
  skip,
}: {
  setLoading: (loading: boolean) => void;
  skip?: boolean | undefined;
}) => {
  // TODO: Tech debt - Migrate code to use codegen hooks with Typescript safety.
  // eslint-disable-next-line no-restricted-syntax
  const { data }: QueryResult | undefined = useQuery(identityAndTraitsQuery, {
    skip,
  });
  const { flagsmithId, flagsmithIdentityData } = data?.currentActor ?? {};
  const flagsmithInstance = useFlagsmith();

  useEffect(() => {
    if (flagsmithId != null) {
      const updateFlagsmithIdentity = async () => {
        await flagsmithInstance.identify(flagsmithId, flagsmithIdentityData);
        setLoading(false);
      };

      void updateFlagsmithIdentity();
    }
  }, [flagsmithId, flagsmithIdentityData, flagsmithInstance, setLoading]);

  return null;
};

export const FeatureFlagProvider = ({
  children,
  skipIdentityLoading = false,
  isAnonymous = false,
}: {
  children: FlagsmithContextType['children'];
  skipIdentityLoading?: boolean;
  isAnonymous?: boolean;
}): JSX.Element => {
  const [loading, setLoading] = useState(true);

  if (isAnonymous) {
    return (
      <FlagsmithProvider
        flagsmith={flagsmith}
        options={{
          // https://docs.flagsmith.com/clients/javascript#initialisation-options
          cacheFlags: true,
          api: '/',
          cacheOptions: { ttl: flagCacheTTL, skipAPI: true },
          enableLogs: false, // set true for logs
          environmentID: flagsmithAnonymousEnvironmentId,
          headers: { 'X-CSRF-TOKEN': window._auth_token },
          preventFetch: true,
        }}
      >
        <FlagsmithIdentityLoadingContext.Provider value={loading}>
          <>
            <FlagsmithAnonymousIdentityAndTraitsLoader setLoading={setLoading} />
            {children}
          </>
        </FlagsmithIdentityLoadingContext.Provider>
      </FlagsmithProvider>
    );
  }
  return (
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        // https://docs.flagsmith.com/clients/javascript#initialisation-options
        cacheFlags: true,
        cacheOptions: { ttl: flagCacheTTL, skipAPI: true },
        enableLogs: false, // set true for logs
        environmentID: flagsmithEnvironmentId,
        preventFetch: true, // this flag only prevents initial fetch. After we identify the user things will get fetched
      }}
    >
      <FlagsmithIdentityLoadingContext.Provider value={loading}>
        <>
          <FlagsmithIdentityAndTraitsLoader
            setLoading={setLoading}
            skip={skipIdentityLoading}
          />
          {children}
        </>
      </FlagsmithIdentityLoadingContext.Provider>
    </FlagsmithProvider>
  );
};
