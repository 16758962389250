/* Supporting Libraries */
import { ComponentType, JSX } from 'react';
import { Navigate, useParams, Params } from 'react-router-dom';
import { IFlagsmithFeature } from 'flagsmith/types';
import { isString } from 'lodash';
import {
  RedirectUnlessAllowedParams,
  RedirectUnlessFeatureFlagEnabled,
  RedirectUnlessAllowedFeatureFlagsProps,
} from '../components/RedirectUnless';
import { SupportedFeatureFlags, useLoadFeatureFlags } from '../hooks/useLoadFeatureFlags';

export type RoutableComponent<Params> = ComponentType<Params> & {
  isRoutableComponent: true;
};

type FlaggedRouteProps = {
  flag: SupportedFeatureFlags;
  path?: string;
  checkValue?: (value: IFlagsmithFeature['value']) => boolean;
  enabled: RoutableComponent<Params>;
  disabled: RoutableComponent<Params>;
};

export const FlaggedRoute: RoutableComponent<FlaggedRouteProps> = ({
  flag,
  enabled,
  disabled,
  checkValue = () => true,
  ...props
}: FlaggedRouteProps): JSX.Element => {
  const { flags } = useLoadFeatureFlags([flag]);
  const Component = flags[flag].enabled && checkValue(flags[flag].value) ? enabled : disabled;
  return <Component {...props} />;
};

FlaggedRoute.isRoutableComponent = true;

type RedesignRouteProps = Omit<FlaggedRouteProps, 'flag'>;
export const RedesignRoute: RoutableComponent<RedesignRouteProps> = (
  props: RedesignRouteProps,
): JSX.Element => {
  return (
    <FlaggedRoute
      {...props}
      flag="flag_app_redesign_2024"
      checkValue={(flagValue) => {
        if (isString(flagValue) && isString(props.path)) {
          const enabledPaths = JSON.parse(flagValue) as string[];
          return enabledPaths.includes(props.path);
        }

        return false;
      }}
    />
  );
};

RedesignRoute.isRoutableComponent = true;

/**
 * This method should be used sparingly to mark a component safe for routing. We ideally
 * only want components that are lazy loaded or are composed of components which are lazy
 * loaded. Not doing this will cause bloat accross the application.
 */
export const dangerous_markComponentAsRoutable = <Params,>(
  component: ComponentType<Params>,
): RoutableComponent<Params> => {
  const newComponent = component as unknown as RoutableComponent<Params>;
  newComponent.isRoutableComponent = true;

  return newComponent;
};

export class RouteDefinition<P extends object> {
  public readonly path: string;

  private readonly component: RoutableComponent<P>;

  private readonly props: P;

  public constructor({
    path,
    component,
    props,
  }: {
    path: string;
    component: RoutableComponent<P>;
    props: P;
  }) {
    this.path = path;
    this.component = component;
    this.props = props;
  }

  public render(): JSX.Element {
    const Component = this.component;
    return (
      <Component
        path={this.path}
        {...this.props}
      />
    );
  }
}

type NewURLFunction = (params: Readonly<Params>) => string;

export const redirector = <Params extends object>(
  newURLFunction: NewURLFunction,
): RoutableComponent<Params> => {
  const Component = (): JSX.Element => {
    const params = useParams();
    const newURL = newURLFunction(params);

    return <Navigate to={newURL} />;
  };

  return dangerous_markComponentAsRoutable(Component);
};

export const redirectUnlessAllowedParams = <Params extends object>({
  allowedParams,
  to,
  component,
}: {
  allowedParams: Record<string, string[]>;
  to: string;
  component: RoutableComponent<Params>;
}): RoutableComponent<Params> => {
  const ComponentToRender = component;
  const Component = (props: Params): JSX.Element => {
    return (
      <RedirectUnlessAllowedParams
        to={to}
        allowedParams={allowedParams}
      >
        <ComponentToRender {...props} />
      </RedirectUnlessAllowedParams>
    );
  };

  return dangerous_markComponentAsRoutable(Component);
};

type RedirectUnlessAllowedFeatureFlagsPropsWithRoutableChildren<Params extends object> = Omit<
  RedirectUnlessAllowedFeatureFlagsProps,
  'children'
> & {
  component: RoutableComponent<Params>;
};

export const redirectUnlessFeatureFlagEnabled = <Params extends object>({
  to,
  flag,
  component,
  hardRedirect,
  flagConditionToAllow,
}: RedirectUnlessAllowedFeatureFlagsPropsWithRoutableChildren<Params>): RoutableComponent<Params> => {
  const ComponentToRender = component;
  const Component = (props: Params): JSX.Element => {
    return (
      <RedirectUnlessFeatureFlagEnabled
        to={to}
        flag={flag}
        hardRedirect={hardRedirect}
        flagConditionToAllow={flagConditionToAllow}
      >
        <ComponentToRender {...props} />
      </RedirectUnlessFeatureFlagEnabled>
    );
  };

  return dangerous_markComponentAsRoutable(Component);
};
