import { useMemo } from 'react';
import { useOnboardingActivitiesByFeatureIdService } from './useOnboardingActivitiesByFeatureIdService';
import { useCreateOnboardingActivityService } from './useCreateOnboardingActivityService';
import { StorageOptions } from '../types';
import { useBackfillToServer } from './useBackfillToServer';

export interface StorageService {
  createOnboardingActivity: ReturnType<typeof useCreateOnboardingActivityService>;
  getOnboardingActivities: ReturnType<typeof useOnboardingActivitiesByFeatureIdService>;
  backfillOnboardingActivity?: ReturnType<typeof useBackfillToServer>;
}

export const noOpStorageService: StorageService = {
  createOnboardingActivity: async () => Promise.resolve(null),
  getOnboardingActivities: async () => Promise.resolve(null),
};

export const useServerStorageService = (): StorageService => {
  const createOnboardingActivity = useCreateOnboardingActivityService();
  const getOnboardingActivities = useOnboardingActivitiesByFeatureIdService();
  const backfillOnboardingActivity = useBackfillToServer();

  return useMemo(
    () => ({
      createOnboardingActivity,
      getOnboardingActivities,
      backfillOnboardingActivity,
    }),
    [backfillOnboardingActivity, createOnboardingActivity, getOnboardingActivities],
  );
};

export const useStorageServices = (): Record<StorageOptions, StorageService> => {
  const serverStorageService = useServerStorageService();

  return useMemo(
    () => ({
      none: noOpStorageService,
      server: serverStorageService,
    }),
    [serverStorageService],
  );
};
