import { useEffect, useState } from 'react';
import { isNotNil, Nullable, Undefinable } from '~/utilities/type-guards';
import { noOpFn } from '~/utilities/util';
import { observeElementRect } from './observeElementRect';
import { ElementType } from './types';

export const useObserveElementRect = (element: Nullable<ElementType>): Undefinable<DOMRect> => {
  const [rect, setRect] = useState<Undefinable<DOMRect>>(undefined);

  useEffect(() => {
    if (isNotNil(element)) {
      const unobserve = observeElementRect(element, setRect);

      return () => {
        setRect(undefined);
        unobserve();
      };
    }

    return noOpFn;
  }, [element]);

  return rect;
};
