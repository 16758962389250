import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '../ErrorBoundary';
import { HardRedirect } from '../HardRedirect';
import { ScrollToTop } from '../ScrollToTop';
import { AnonymousProject } from '../../screens/AnonymousProject';
import { AnonymousMedia } from '../../screens/AnonymousMedia';
import { LiveStreamAudienceRouter } from '../../screens/LiveStreamScreen/AudienceRouter';
import { useGTM } from '~/hooks/useGTM';
import { Broadcast } from '~/singlePageApp/screens/LiveStreamScreen/Broadcast';
import { AnonymousRecord } from '../../screens/AnonymousRecord';
import { UnauthorizedMedia } from '../../screens/UnauthorizedMedia';
import { AnonymousEdit } from '~/singlePageApp/screens/AnonymousEdit';
import { ContactRequest } from '~/singlePageApp/screens/ContactRequest';
import { MediaReview } from '~/singlePageApp/screens/MediaReview';
import { Activation } from '~/singlePageApp/screens/Activation';
import { FolderInvitation, ChannelInvitation } from '~/singlePageApp/screens/ContentInvitation';
import { GroupRecordAnonymousStage } from '~/singlePageApp/screens/GroupRecordAnonymous';

/**
 * the router for AnonymousAppRouter
 *
 * @param {Object} [params]
 * @param {Object} params.windowObject - Defaults to `window`. Can be changed out for testing.
 */
export const AnonymousAppRouter = () => {
  const gtmEvent = useGTM({ isAnonymous: true });
  const location = useLocation();

  useEffect(() => {
    gtmEvent('w.pageView');
  }, [location, gtmEvent]);

  return (
    <ScrollToTop>
      <Routes>
        <Route
          path="/folders/:projectHashedId"
          element={
            <ErrorBoundary>
              <AnonymousProject />
            </ErrorBoundary>
          }
        />
        <Route
          path="/folders/:projectHashedId/subfolders/:subfolderHashedId"
          element={
            <ErrorBoundary>
              <AnonymousProject />
            </ErrorBoundary>
          }
        />
        <Route
          path="/medias/:mediaHashedId"
          element={
            <ErrorBoundary>
              <AnonymousMedia />
            </ErrorBoundary>
          }
        />

        <Route
          path="/reviews/:reviewLinkHashedId"
          element={<MediaReview loggedIn={false} />}
        />

        <Route
          path="/medias/unauthorized"
          element={<UnauthorizedMedia />}
        />

        <Route
          path="/anonymous/record"
          element={<AnonymousRecord />}
        />

        <Route
          path="/anonymous/editor"
          element={<AnonymousEdit />}
        />

        <Route
          path="/live/join/:hashedId/:joinKey"
          element={<Broadcast userType="panelist" />}
        />
        <Route
          path="/anonymous/group-record/:hashedId/:joinKey"
          element={<GroupRecordAnonymousStage />}
        />
        <Route
          path="/live/events/pfrbj1z0qm"
          element={<HardRedirect to="/live/events/1abg5av1km" />}
        />
        <Route
          path="/live/events/:hashedId"
          element={<LiveStreamAudienceRouter />}
        />
        <Route
          path="/embed/live/events/:hashedId"
          element={<LiveStreamAudienceRouter />}
        />
        <Route
          path="/contact_requests/:hashedId"
          element={<ContactRequest />}
        />
        <Route
          path="/activate/:activationCode"
          element={<Activation />}
        />
        <Route
          path="/folder_invitations/:folderHashedId"
          element={<FolderInvitation />}
        />
        <Route
          path="/channel_invitations/:channelHashedId"
          element={<ChannelInvitation />}
        />
        <Route
          path="/404"
          element={<HardRedirect to="/404" />}
        />
        <Route
          path="/500"
          element={<HardRedirect to="/500" />}
        />
        <Route
          path="*"
          element={<HardRedirect to="/login" />}
        />
      </Routes>
    </ScrollToTop>
  );
};
