import { useCallback } from 'react';
import { NilableArray } from '~/utilities/type-guards';
import { OnboardingActivityAction } from '~/graphql';
import { useOnboardingActivitiesLazyQuery } from './__gql/onboardingActivitiesByFeatureId.gql.gen';
import { SupportedFeatureIds } from '../types';
import { getUniqueFeatureId } from '../utils/getUniqueFeatureId';

export const useOnboardingActivitiesByFeatureIdService = (): ((
  featureId: SupportedFeatureIds,
) => Promise<
  NilableArray<{
    id: string;
    action: OnboardingActivityAction;
  }>
>) => {
  const [load] = useOnboardingActivitiesLazyQuery();

  return useCallback(
    async (featureId: SupportedFeatureIds) => {
      const { data } = await load({
        variables: { featureId: getUniqueFeatureId(featureId) },
        fetchPolicy: 'no-cache',
      });

      return data?.currentUser?.onboardingActivities?.nodes;
    },
    [load],
  );
};
