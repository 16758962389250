import { UserType } from '~/singlePageApp/screens/LiveStreamScreen/Broadcast/types/UserType';
import { lazyWithSuspense } from '~/utilities/lazyWithSuspense';

export interface BroadcastProps {
  userType: UserType;
  isNewRegistration?: boolean;
}

export const Broadcast = lazyWithSuspense<BroadcastProps, 'Broadcast'>(
  async () => import('./Broadcast'),
  {
    exportKey: 'Broadcast',
  },
);
