import { useCallback } from 'react';
import { Nilable } from '~/utilities/type-guards';
import { OnboardingActivityAction } from '~/graphql';
import { useCreateOnboardingActivityMutation } from './__gql/createOnboardingActivity.gql.gen';
import { getUniqueFeatureId } from '../utils/getUniqueFeatureId';
import { SupportedFeatureIds } from '../types';

export const useCreateOnboardingActivityService = (): ((
  action: OnboardingActivityAction,
  featureId: SupportedFeatureIds,
) => Promise<
  Nilable<{
    id: string;
    action: OnboardingActivityAction;
    featureId: string;
  }>
>) => {
  const [createOnboardingActivity] = useCreateOnboardingActivityMutation();

  return useCallback(
    async (action: OnboardingActivityAction, featureId: SupportedFeatureIds) => {
      const { data } = await createOnboardingActivity({
        variables: {
          input: {
            action,
            featureId: getUniqueFeatureId(featureId),
          },
        },
      });

      return data?.createOnboardingActivity?.onboardingActivity;
    },
    [createOnboardingActivity],
  );
};
