import { useCallback } from 'react';
import { isNil } from '~/utilities/type-guards';
import { trackStructEvent } from '~/utilities/snowplow';
import { useCreateOnboardingActivityService } from './useCreateOnboardingActivityService';
import { SupportedFeatureIds } from '../types';
import { BackfillUtils } from '../hooks/useOnboarding/OnboardingProvider';

export const useBackfillToServer = (): ((
  featureId: SupportedFeatureIds,
  backfillUtils: BackfillUtils,
) => Promise<{
  backfilled: boolean;
}>) => {
  const createOnboardingActivity = useCreateOnboardingActivityService();

  return useCallback(
    async (
      featureId: SupportedFeatureIds,
      backfillUtils: BackfillUtils,
    ): Promise<{ backfilled: boolean }> => {
      if (isNil(backfillUtils.getItem())) {
        return { backfilled: false };
      }

      try {
        await createOnboardingActivity('view', featureId);
        backfillUtils.removeItem();
        trackStructEvent({
          category: 'onboarding',
          action: 'server-backfill-complete',
          value: featureId,
        });
        return { backfilled: true };
      } catch {
        return { backfilled: false };
      }
    },
    [createOnboardingActivity],
  );
};
