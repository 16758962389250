import { isNil, isEmptyRecord, Nilable } from '~/utilities/type-guards';

interface EntriesResult {
  __typename?: string;
  entries?: Record<string, unknown>[];
  error?: string;
}

export const parseEntriesResult = (
  entriesResult?: Nilable<EntriesResult>,
): {
  entries: Record<string, unknown>[];
  error?: { error?: string };
  hasError: boolean;
  type?: string;
} => {
  if (isNil(entriesResult) || isEmptyRecord(entriesResult)) {
    return {
      entries: [],
      error: {},
      hasError: false,
      type: undefined,
    };
  }

  if (!('entries' in entriesResult) || isNil(entriesResult.entries)) {
    return {
      entries: [],
      error: entriesResult,
      hasError: true,
      type: undefined,
    };
  }

  const { entries, __typename: type } = entriesResult as {
    entries: Record<string, unknown>[];
    __typename: string;
  };

  return {
    entries,
    error: undefined,
    hasError: false,
    type,
  };
};
