import { PropsWithChildren, JSX } from 'react';
import { createPortal } from 'react-dom';
import { isNil } from '~/utilities/type-guards';
import { useTargetObserver } from '../hooks/useTargetObserver';
import { GetTargetFn } from '../types';

export const TargetPortal = ({
  getTarget,
  children,
}: PropsWithChildren<{
  getTarget: GetTargetFn;
}>): JSX.Element | null => {
  const { target } = useTargetObserver(getTarget, true);

  if (isNil(target)) {
    return null;
  }

  return createPortal(children, target);
};
