import { differenceInCalendarDays, millisecondsToMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { datetimeHelpers } from '@wistia/vhs';
import isNil from 'lodash/isNil';

const { buildTimeDuration } = datetimeHelpers;

/**
 * @type {[string]}
 */
const defaultLocales = ['en-US']; // TODO: figure languages other than english
const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
const isInvalidDate = (date: Date | typeof NaN | null): boolean =>
  !(date instanceof Date) || isNil(date) || date.getTime() === 0;
const HOUR_MINUTES = 60;
const MINUTE_SECONDS = 60;
/**
 * Converts a Date object into a date only string.
 *
 * e.g. Jun 3, 2021
 * @param {Date} date a Date object you want to convert into a string
 * @param {Object} [options]
 * @param {string} options.timeZone - the timezone you want date displayed in, defaults to current users time zone
 * @returns {string} a formatted date string
 */
export const dateOnlyString = (date: Date | null, { timeZone = defaultTimeZone } = {}): string => {
  if (isNil(date) || isInvalidDate(date)) {
    return '';
  }

  try {
    return new Intl.DateTimeFormat(defaultLocales, {
      dateStyle: 'medium',
      timeZone,
    }).format(date);
  } catch (error) {
    return ''; // handle bad input
  }
};

/**
 * Converts a Date object into a date only string formatted numerically.
 *
 * e.g. 06/03/2021
 * @param {Date} date a Date object you want to convert into a string
 * @param {Object} [options]
 * @param {string} options.timeZone - the timezone you want date displayed in, defaults to current users time zone
 * @returns {string} a formatted date string
 */
export const dateOnlyStringNumeric = (
  date: Date | typeof NaN | null,
  { timeZone = defaultTimeZone } = {},
): string => {
  if (isNil(date) || isInvalidDate(date)) {
    return '';
  }

  try {
    return new Intl.DateTimeFormat(defaultLocales, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone,
    }).format(date);
  } catch (error) {
    return ''; // handle bad input
  }
};

/**
 * Converts a Date object into a date only string formatted to ISO8601.
 *
 * e.g. 2021-06-03
 * @param {Date} date a Date object you want to convert into a string
 * @param {Object} [options]
 * @param {string} options.timeZone - the timezone you want date displayed in, defaults to current users time zone
 * @returns {string} a formatted date string
 */
export const dateOnlyISOString = (
  date: Date | typeof NaN | null,
  { timeZone = defaultTimeZone } = {},
): string => {
  if (isNil(date) || isInvalidDate(date)) {
    return '';
  }

  try {
    const formatter = new Intl.DateTimeFormat(defaultLocales, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone,
    });
    const parts = formatter.formatToParts(date);
    const partsAsObject = parts.reduce(
      (map, obj) => {
        // eslint-disable-next-line no-param-reassign
        map[obj.type] = obj.value;
        return map;
      },
      { year: '', month: '', day: '' },
    );
    return `${partsAsObject.year}-${partsAsObject.month}-${partsAsObject.day}`;
  } catch (error) {
    return ''; // handle bad input
  }
};

/**
 * Converts a Date object into a month and day string formatted numerically.
 *
 * e.g. 06/03/2021
 * @param {Date} date a Date object you want to convert into a string
 * @param {Object} [options]
 * @param {string} options.timeZone - the timezone you want date displayed in, defaults to current users time zone
 * @returns {string} a formatted date string
 */
export const monthDayStringNumeric = (
  date: Date | typeof NaN | null,
  { timeZone = defaultTimeZone } = {},
): string => {
  if (isNil(date) || isInvalidDate(date)) {
    return '';
  }

  try {
    return new Intl.DateTimeFormat(defaultLocales, {
      month: '2-digit',
      day: '2-digit',
      timeZone,
    }).format(date);
  } catch (error) {
    return ''; // handle bad input
  }
};
/**
 * Converts a Date object into a month and day string formatted numerically.
 *
 * e.g. Thursday
 * @param {Date} date a Date object you want to convert into a string
 * @param {Object} [options]
 * @param {string} options.timeZone - the timezone you want date displayed in, defaults to current users time zone
 * @returns {string} a formatted date string
 */
export const dayOfWeekString = (date: Date | null, { timeZone = defaultTimeZone } = {}): string => {
  if (isNil(date) || isInvalidDate(date)) {
    return '';
  }

  try {
    return new Intl.DateTimeFormat(defaultLocales, {
      weekday: 'long',
      timeZone,
    }).format(date);
  } catch (error) {
    return ''; // handle bad input
  }
};

/**
 * Converts a Date object into a time only string.
 *
 * e.g. 7:30 AM
 * @param {Date} date a Date object you want to convert into a string
 * @param {Object} [options]
 * @param {string} options.timeZone - the timezone you want date displayed in, defaults to current users time zone
 * @returns {string} a formatted date string
 */
export const timeOnlyString = (date: Date | null, { timeZone = defaultTimeZone } = {}): string => {
  if (isNil(date) || isInvalidDate(date)) {
    return '';
  }

  try {
    return new Intl.DateTimeFormat(defaultLocales, {
      timeStyle: 'short',
      timeZone,
    }).format(date);
  } catch (error) {
    return ''; // handle bad input
  }
};

/**
 * Converts a Date object into a date and time string.
 *
 * e.g. Jun 3, 2021, 11:52 AM
 *
 * NOTE: you should probably use timeAgoString when trying to display
 * an updated/created timestamp.
 *
 * @param {Date} date - a Date object you want to convert into a string
 * @param {Object} [options]
 * @param {string} options.timeZone - the timezone you want date displayed in, defaults to current users time zone
 * @returns {string} a formatted date string
 */
export const dateTimeString = (date: Date | null, { timeZone = defaultTimeZone } = {}): string => {
  if (isNil(date) || isInvalidDate(date)) {
    return '';
  }
  try {
    return formatInTimeZone(date, timeZone, 'MMM d, yyyy, h:mm a');
  } catch (error) {
    return ''; // handle bad input
  }
};

/**
 * Converts a Date object into a date and time string
 * for use in a sentence.
 *
 * e.g. June 3, 2021, 11:52 AM
 *
 * NOTE: you should probably use timeAgoString when trying to display
 * an updated/created timestamp.
 *
 * @param {Date} date - a Date object you want to convert into a string
 * @param {Object} [options]
 * @param {string} options.timeZone - the timezone you want date displayed in, defaults to current users time zone
 * @returns {string} a formatted date string
 */
export const dateTimeStringForSentence = (
  date: Date | null,
  { timeZone = defaultTimeZone } = {},
): string => {
  if (isNil(date) || isInvalidDate(date)) {
    return '';
  }

  try {
    return new Intl.DateTimeFormat(defaultLocales, {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZone,
    }).format(date);
  } catch (error) {
    return ''; // handle bad input
  }
};

/**
 * Converts a Date object into a date and time string
 * without the year.
 *
 * e.g. June 3, 11:52 AM
 *
 * NOTE: you should probably use timeAgoString when trying to display
 * an updated/created timestamp.
 *
 * @param {Date} date - a Date object you want to convert into a string
 * @param {Object} [options]
 * @param {string} options.timeZone - the timezone you want date displayed in, defaults to current users time zone
 * @param {Date} options.nowAnchor - The date used to calculate relative to now. Defaults to current date but can be overwritten for tests.
 * @returns {string} a formatted date string
 */
export const dateTimeWithoutYearString = (
  date: Date | null,
  { timeZone = defaultTimeZone, nowAnchor = new Date() } = {},
): string => {
  if (isNil(date) || isInvalidDate(date)) {
    return '';
  }

  try {
    if (date.getFullYear() === nowAnchor.getFullYear()) {
      return formatInTimeZone(date, timeZone, 'MMM d, h:mm a');
    }
    return dateTimeString(date, { timeZone });
  } catch (error) {
    return ''; // handle bad input
  }
};

/**
 * Converts a Date object into a date only string
 * for use in a sentence.
 *
 * e.g. June 3, 2021
 *
 * @param {Date} date - a Date object you want to convert into a string
 * @param {Object} [options]
 * @param {string} options.timeZone - the timezone you want date displayed in, defaults to current users time zone
 * @returns {string} a formatted date string
 */
export const dateOnlyStringForSentence = (
  date: Date | null,
  { timeZone = defaultTimeZone } = {},
): string => {
  if (isNil(date) || isInvalidDate(date)) {
    return '';
  }

  try {
    return new Intl.DateTimeFormat(defaultLocales, {
      dateStyle: 'long',
      timeZone,
    }).format(date);
  } catch (error) {
    return ''; // handle bad input
  }
};

/**
 * Shows time ago relative to current time.
 *
 * examples:
 *
 *   < 1 minute ago
 *
 *   33 minutes ago
 *
 *   Today, 3:30 PM
 *
 *   Yesterday, 6:22 AM
 *
 *   Nov 11, 11:32 AM
 *
 *   Feb 23, 2020, 1:55 PM
 *
 * NOTE: timeAgoString doesn't support multiple time zones since doing so would
 * complicate calculations for whether to use "Today" or "Yesterday".
 * @param {Date} date - the date relative to now
 * @param {Object} [options]
 * @param {Date} options.nowAnchor - The date used to calculate relative to now. Defaults to current date but can be overwritten for tests.
 * @returns {string} - a string representation of the date.
 */
export const timeAgoString = (date: Date, { nowAnchor = new Date() } = {}): string => {
  if (isInvalidDate(date)) {
    return '';
  }

  const minutesAgo = (nowAnchor.valueOf() - date.valueOf()) / (MINUTE_SECONDS * 1000);
  const minutesAgoRounded = Math.round(minutesAgo);
  const differenceInDays = differenceInCalendarDays(nowAnchor, date); // NOTE: we don't use isYesterday or isToday since it is not functionally pure and cannot be tested.

  // handle edge case where date is in future
  if (minutesAgo < 0) {
    return dateTimeString(date);
  }

  if (minutesAgo < 1) {
    return '< 1 minute ago';
  }
  if (minutesAgoRounded < 2) {
    return '1 minute ago';
  }
  if (minutesAgoRounded <= HOUR_MINUTES) {
    return `${minutesAgoRounded} minutes ago`;
  }
  if (differenceInDays === 0) {
    return `Today, ${Intl.DateTimeFormat(defaultLocales, { timeStyle: 'short' }).format(date)}`;
  }
  if (differenceInDays === 1) {
    return `Yesterday, ${Intl.DateTimeFormat(defaultLocales, { timeStyle: 'short' }).format(date)}`;
  }
  if (date.getFullYear() === nowAnchor.getFullYear()) {
    return Intl.DateTimeFormat(defaultLocales, {
      day: 'numeric',
      month: 'short',
      hour: 'numeric',
      minute: '2-digit',
    }).format(date);
  }

  return dateTimeString(date);
};

/**
 * Given a date, shows the UTC offset.
 * @param {Date} date a Date object for calculating offset.
 */
export const dateUTCOffset = (date: Date): string => {
  const offsetInHours = (date.getTimezoneOffset() / HOUR_MINUTES) * -1;
  const hours = Math.round(offsetInHours);
  const minutes = (offsetInHours - hours) * HOUR_MINUTES;
  const prefix = hours >= 0 ? '+' : '';
  const hoursString = `${hours}`.padStart(2, '0');
  const minutesString = `${minutes}`.padStart(2, '0');

  return `${prefix}${hoursString}:${minutesString}`;
};

const padTimeInteger = (number: number) => {
  return number.toString().padStart(2, '0');
};

/**
 * A string representation of a duration for a media. Assumes most medias
 * are under an hour so only shows hours if media is over hour.
 *
 * @param {number} numberOfMilliseconds
 * @returns {string} - a string representation for duration
 */
export const mediaDurationString = (numberOfMilliseconds: number): string => {
  const { hours, minutes, seconds } = buildTimeDuration(numberOfMilliseconds);

  if (hours < 1) {
    return `${minutes}:${padTimeInteger(seconds)}`;
  }

  return `${hours}:${padTimeInteger(minutes)}:${padTimeInteger(seconds)}`;
};

/**
 * A string representation of a duration for a user session. Assumes that
 * sessions may or may not be more than an hour. To prevent confusion all
 * times show hours, even those that are less than an hour.
 *
 * @param {number} numberOfMilliseconds
 * @returns {string} - a string representation of duration
 */
export const sessionDurationString = (numberOfMilliseconds: number): string => {
  const { hours, minutes, seconds } = buildTimeDuration(numberOfMilliseconds);

  return `${hours}:${padTimeInteger(minutes)}:${padTimeInteger(seconds)}`;
};

/**
 * A string representation of a duration for an event, showing hours:minutes for
 * long sessions and minutes:seconds for sessions under 10 minutes
 *
 * @param {number} numberOfMilliseconds
 * @returns {string} - a string representation of duration, eg: "1:23 hrs" or "9:33 min"
 */
export const eventDurationString = (numberOfMilliseconds: number): string => {
  const { hours, minutes, seconds } = buildTimeDuration(numberOfMilliseconds);

  const paddedMin = `${minutes}`.padStart(2, '0');
  const paddedSec = `${seconds}`.padStart(2, '0');
  const MINUTES_DISPLAY_THRESHOLD = 10;

  return millisecondsToMinutes(numberOfMilliseconds) >= MINUTES_DISPLAY_THRESHOLD
    ? `${hours}:${paddedMin} hrs`
    : `${minutes}:${paddedSec} min`;
};
