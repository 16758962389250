import { createContext } from 'react';
import { Undefinable } from '~/utilities/type-guards';

export type SnowplowContextType = {
  label: Undefinable<string>;
  step?: number;
};

export const DEFAULT_CONTEXT_VALUE = { label: undefined, step: undefined };

export const SnowplowContext = createContext<SnowplowContextType>(DEFAULT_CONTEXT_VALUE);
