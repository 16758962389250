import { Dispatch, SetStateAction, createContext } from 'react';
import { noOpFn } from '~/utilities/util';
import { SupportedFeatureIds } from '~/onboarding/types';
import { Nilable, Undefinable } from '~/utilities/type-guards';
import { Experience, OnboardingConfiguration } from './OnboardingProvider';

export type OnboardingContextType = {
  configure: (config: OnboardingConfiguration) => void;
  show: (featureId: string) => void;
  hide: (featureId: string) => void;
  next: (featureId: string) => void;
  prev: (featureId: string) => void;
  getCurrentExperience: () => Nilable<Experience>;
  getCurrentFeatureId: () => Undefinable<SupportedFeatureIds>;
  currentIndex: number;
  setCurrentIndex: Dispatch<SetStateAction<number>>;
};

export const NOOP_ONBOARDING_CONTEXT: OnboardingContextType = {
  configure: noOpFn,
  show: noOpFn,
  hide: noOpFn,
  next: noOpFn,
  prev: noOpFn,
  getCurrentExperience: () => undefined,
  getCurrentFeatureId: () => undefined,
  currentIndex: -1,
  setCurrentIndex: noOpFn,
};

export const OnboardingContext = createContext<OnboardingContextType | null>(null);
