import { PropsWithChildren, JSX } from 'react';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { BottlerApolloContext } from './BottlerApolloContext';
import { bottlerClient } from './bottlerClient';

/**
 * The client prop allows our unit tests to insert a graphql client with mocks.
 * Otherwise, we default to the bottlerClient object.
 */

type BottlerApolloProviderProps = PropsWithChildren<{
  client?: ApolloClient<NormalizedCacheObject>;
}>;

export const BottlerApolloProvider = ({
  children,
  client = bottlerClient,
}: BottlerApolloProviderProps): JSX.Element => (
  <BottlerApolloContext.Provider value={client}>{children}</BottlerApolloContext.Provider>
);
