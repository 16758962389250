import { useFlagsmith } from 'flagsmith/react';
import { useEffect } from 'react';
import { isNonEmptyString } from '~/utilities/type-guards';
import { subdomain } from '../../constantDefinitions';
import { useAnonymousFlagsmithIdentityAndTraitsLoaderQuery } from './__gql/FlagsmithAnonymousIdentityAndTraitsLoader.gql.gen';

export const FlagsmithAnonymousIdentityAndTraitsLoader = ({
  setLoading,
}: {
  setLoading: (loading: boolean) => void;
}): null => {
  const flagsmithInstance = useFlagsmith();
  const { data } = useAnonymousFlagsmithIdentityAndTraitsLoaderQuery();

  const freePlan = data?.currentAccount?.plan?.free;
  const flagsmithId = data?.currentAccount?.flagsmithId;

  useEffect(() => {
    const updateFlagsmithIdentity = async () => {
      if (isNonEmptyString(flagsmithId)) {
        await flagsmithInstance.identify(flagsmithId, {
          // eslint-disable-next-line camelcase
          account_key: subdomain,
          // eslint-disable-next-line camelcase
          free_plan: Boolean(freePlan),
        });
        setLoading(false);
      }
    };

    void updateFlagsmithIdentity();
  }, [flagsmithId, flagsmithInstance, freePlan, setLoading]);

  return null;
};
