/* eslint-disable */
/*
 * This was automatically generated by webpack/onboarding/onboarding.ts.
 * DO NOT MANUALLY EDIT
 * Please refer to https://wistia.github.io/wistia/main/?path=/docs/onboarding-docs-getting-started--docs for more information.
 */

export const activeOnboarding = {
  anonEditDemo: {
    id: 'anonEditDemo-2fc94392-f48b-4eac-a3fc-adf72e044a6c',
    pillar: 'growth',
    product: 'onboarding'
  },
  checklistFirstAction: {
    id: 'checklistFirstAction-849da6cf-a6e8-4201-b9ce-e0f9b04f0868',
    pillar: 'growth',
    product: 'onboarding'
  },
  recordTakeManagementDemo: {
    id: 'recordTakeManagementDemo-38bfead5-dc6b-44fa-89cf-6e2323c99a62',
    pillar: 'create',
    product: 'record'
  },
  editRecordingOnboarding: {
    id: 'editRecordingOnboarding-7a4feaae-ffac-4582-8d98-313ff891be6b',
    pillar: 'create',
    product: 'record'
  },
  updatedFolderPermissions: {
    id: 'updatedFolderPermissions-657d56be-673b-4f18-b7e4-622b9daf07ea',
    pillar: 'organize',
    product: 'manage'
  },
  subfolders: {
    id: 'subfolders-8ce16f48-baa0-4538-a1ca-df0e06a66918',
    pillar: 'organize',
    product: 'discover'
  },
  embedButton: {
    id: 'embedButton-e1236cdd-fa36-4188-b76b-709882b405d9',
    pillar: 'publish',
    product: 'media-page'
  },
  shareForReviewHotspot: {
    id: 'shareForReviewHotspot-74d0c31c-c0e1-4f33-bc23-1909344b654e',
    pillar: 'organize',
    product: 'manage'
  },
  channelEmbedButton: {
    id: 'channelEmbedButton-5a1a5feb-ffa5-40b3-aac0-f8157a893431',
    pillar: 'publish',
    product: 'channel-page'
  },
  channelQuickActions: {
    id: 'channelQuickActions-29fde130-1db3-43b0-a879-4557a223d28b',
    pillar: 'publish',
    product: 'channels-page'
  }
};

export const SUPPORTED_FEATURE_IDS = [
  'anonEditDemo',
  'checklistFirstAction',
  'recordTakeManagementDemo',
  'editRecordingOnboarding',
  'updatedFolderPermissions',
  'subfolders',
  'embedButton',
  'shareForReviewHotspot',
  'channelEmbedButton',
  'channelQuickActions'
] as const
